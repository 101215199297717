import React, { ChangeEvent, useState } from 'react';
import { Button, Modal, Textarea } from '@/ui-kit';
import styles from './modal-complain.module.scss';

interface Props {
    isOpen: boolean;
    isLoading: boolean;
    onClose: () => void;
    onSubmit: (vaue: string) => void;
}

const MAX_COMMENT_LENGTH = 300;

export const ModalComplain: React.FC<Props> = ({ isOpen, isLoading, onClose, onSubmit }) => {
    const [comment, setComment] = useState<string>('');

    const isDisabledTextarea = !comment.length || comment.length > MAX_COMMENT_LENGTH;

    const handleChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value);
    };

    const handleSubmit = () => {
        if (isDisabledTextarea) return;

        onSubmit(comment);
    };

    return (
        <Modal
            className={styles.modal}
            isOpen={isOpen}
            onClose={onClose}
            isDeskorTop={false}
            title="Жалоба"
            withCloseButton={false}
        >
            <Button
                className={styles.buttonCancel}
                onClick={onClose}
            >
                Отмена
            </Button>
            <div className={styles.content}>
                <div className={styles.textareaWrapper}>
                    <Textarea
                        className={styles.textarea}
                        label="Опишите причину жалобы"
                        placeholder="Комментарий"
                        maxLength={MAX_COMMENT_LENGTH}
                        defaultValue={comment}
                        onChange={handleChangeTextArea}
                    />
                </div>
                <Button
                    className={styles.buttonSubmit}
                    typeColor="orange"
                    isDisabled={isDisabledTextarea}
                    isLoading={isLoading}
                    onClick={handleSubmit}
                >
                    Отправить жалобу
                </Button>
            </div>
        </Modal>
    );
};
