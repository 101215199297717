import React from 'react';
import styles from './card-user-info.module.scss';
import clsx from 'clsx';
import { Container, Tag, Typography } from '@/ui-kit';
import { Profile } from '@/types/profile';
import { differenceInYearsResult } from '@/utils/difference-in-years-result';
import { LocationIcon, OfflineStatus, OnlineStatus } from '@/assets/icons';
import { Gender } from '@/constans/gender';
import { ButtonsLikeAndDislike } from '@/components/buttons-like-and-dislike';
import { dataPurposes } from '@/constans/purposes';

type Dir = 'left' | 'right' | null;

interface Props {
    currentSwipeSlide: Profile;
    isVisibleDetails: boolean;
    detailsRef: React.RefObject<HTMLDivElement>;
    onSwipeLeft: (val: Dir) => void;
    onSwipeRight: (val: Dir) => void;
}

export const CardUserInfo: React.FC<Props> = ({
    currentSwipeSlide,
    isVisibleDetails,
    detailsRef,
    onSwipeLeft,
    onSwipeRight,
}) => {
    const currentPurpose = dataPurposes.find((purpose) => purpose.value === currentSwipeSlide?.purpose);

    const getIconComponent = (icon: React.FC<React.SVGProps<SVGSVGElement>>): React.ReactNode => {
        const IconComponent = icon;

        return <IconComponent />;
    };

    return (
        <div className={clsx(styles.descriptionWrapper, isVisibleDetails && styles.visibleDetails)}>
            <Container className={styles.infoWrapper}>
                <div ref={detailsRef} />
                <div className={styles.userInfo__header}>
                    <Typography
                        typeColor="black"
                        fontWeight="medium"
                        variant="span"
                        className={clsx(styles.city)}
                    >
                        {currentSwipeSlide?.is_online ? (
                            <>
                                <OnlineStatus /> Онлайн
                            </>
                        ) : (
                            <>
                                <OfflineStatus /> Оффлайн
                            </>
                        )}
                    </Typography>

                    <Typography
                        typeColor="black"
                        fontWeight="medium"
                        variant="span"
                        className={clsx(styles.city)}
                    >
                        <LocationIcon /> {currentSwipeSlide?.region?.name}
                    </Typography>
                </div>
                {currentSwipeSlide?.public_name && (
                    <Typography
                        typeColor="black"
                        fontWeight="medium"
                        variant="h3"
                        className={styles.cardInfoText}
                    >
                        {currentSwipeSlide?.public_name}
                        {differenceInYearsResult(currentSwipeSlide?.dob) > 0
                            ? ', ' + differenceInYearsResult(currentSwipeSlide?.dob)
                            : null}
                    </Typography>
                )}

                {currentSwipeSlide?.about_me && (
                    <>
                        <div className={styles.label}>
                            <Typography
                                variant="label"
                                fontWeight="normal"
                                fontFamily="SFProText"
                            >
                                Обо мне
                            </Typography>
                        </div>

                        <Typography
                            className={styles.text}
                            variant="p"
                        >
                            {currentSwipeSlide?.about_me}
                        </Typography>
                    </>
                )}

                {currentPurpose && (
                    <Tag
                        className={clsx(styles.tagInfo, styles.isIcon)}
                        icon={getIconComponent(currentPurpose.icon)}
                    >
                        <div>
                            <Typography
                                className={styles.tagInfo__title}
                                variant="p"
                            >
                                Я ищу
                            </Typography>
                            <Typography
                                className={styles.tagInfo__text}
                                variant="p"
                                fontWeight="bold"
                            >
                                {currentPurpose.text}
                            </Typography>
                        </div>
                    </Tag>
                )}

                {currentSwipeSlide?.travel_plans && (
                    <>
                        <div className={styles.label}>
                            <Typography
                                variant="label"
                                fontWeight="normal"
                                fontFamily="SFProText"
                            >
                                Тревел мечты
                            </Typography>
                        </div>

                        <Typography
                            className={styles.text}
                            variant="p"
                        >
                            {currentSwipeSlide?.travel_plans}
                        </Typography>
                    </>
                )}

                <ButtonsLikeAndDislike
                    onSwipeLeft={() => onSwipeLeft('left')}
                    onSwipeRight={() => onSwipeRight('right')}
                    visible={true}
                    customClass={styles.actionButtons}
                />
            </Container>
        </div>
    );
};
