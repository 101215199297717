import { useState } from 'react';
import styles from './gallery-images.module.scss';
import { useSwipedChange } from '@/store/useSwipedChange';
import { Photo } from '@/types/profile';
import clsx from 'clsx';
import { Pagination } from './componenets/pagination';
import { Navigation } from './componenets/navigation';
import GalleryImage from '../gallery-image/gallery-image';

interface Props {
    photos: Photo[];
    isVisibleDetails?: boolean;
    isMatched?: boolean;
    isCurrentCard?: boolean;
}

export const GalleryImages: React.FC<Props> = ({ photos, isVisibleDetails, isMatched, isCurrentCard }) => {
    const { isSwiping } = useSwipedChange((state) => state);
    const [currentIndexGallery, setCurrentIndexGallery] = useState(0);
    const currentGalleryImage = photos[currentIndexGallery];
    const dir = useSwipedChange((state) => state.dir);

    const handleChangeGallery = (idx: number, dir: 'left' | 'right') => {
        if (isSwiping) return;

        if (dir === 'left' && idx > 0) {
            setCurrentIndexGallery(idx - 1);
        }

        if (dir === 'right' && idx < photos.length - 1) {
            setCurrentIndexGallery(idx + 1);
        }
    };

    const getGalleryImageClasses = (idx: number) => {
        const isMatchImage = idx === currentIndexGallery;

        return clsx(styles.image, {
            [styles.galleryImageHidden]: !isMatchImage,
            [styles.matchedImage]: isMatched,
            [styles.imageEmpty]: !currentGalleryImage?.main_url,
            ...(isCurrentCard && {
                [styles.gradientRight]: dir === 'right',
                [styles.gradientLeft]: dir === 'left',
            }),
        });
    };

    return (
        <div className={clsx(styles.wrapper, { [styles.visibleDetails]: isVisibleDetails })}>
            <Pagination
                className={styles.pagination}
                total={photos.length}
                activeIndex={currentIndexGallery}
                isVisible={photos.length > 1}
            />
            {photos.map((photo, idx) => (
                <GalleryImage
                    className={getGalleryImageClasses(idx)}
                    key={photo.id}
                    image={photo}
                />
            ))}
            <Navigation
                currentIndex={currentIndexGallery}
                onChange={handleChangeGallery}
            />
        </div>
    );
};
