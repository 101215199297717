import styles from './app-layout.module.scss';
import { MenuApp } from '@/components/menu-app';
import { Outlet, ScrollRestoration } from 'react-router-dom';

export const AppLayout: React.FC = () => {
    return (
        <>
            <ScrollRestoration />
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <Outlet />
                </div>
                <MenuApp className={styles.menu} />
            </div>
        </>
    );
};
