import {
    FunIcon,
    LoveIcon,
    NotSpecified,
    PurposeLoveIcon,
    PurposeCommunicationIcon,
    PurposeNotSpecifiedIcon,
} from '@/assets/icons';
import { Profile } from '@/types/profile';
interface DataGoal {
    value: Profile['purpose'];
    illustarion: React.FC;
    text: string;
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
}
export const dataPurposes: DataGoal[] = [
    { value: 'fun', illustarion: FunIcon, icon: PurposeCommunicationIcon, text: 'Новые друзья' },
    { value: 'love', illustarion: LoveIcon, icon: PurposeLoveIcon, text: 'Романтические отношения' },
    { value: 'not_specified', illustarion: NotSpecified, icon: PurposeNotSpecifiedIcon, text: 'Как пойдет' },
];
