import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Photo } from '@/types/profile';
import { Spiner } from '@/ui-kit';

import styles from './gallery-image.module.scss';

type Props = {
    className?: string;
    image: Photo;
};

const GalleryImage: React.FC<Props> = ({ className, image }) => {
    const [isLoading, setIsLoading] = useState(true);

    const imageUrl = image?.main_url;
    const imageName = image?.metadata?.filename;

    useEffect(() => {
        if (!imageUrl) handleStopLoading();
    }, [imageUrl]);

    const handleStopLoading = () => {
        setIsLoading(false);
    };

    return (
        <div className={clsx(className, styles.wrapper)}>
            {isLoading && (
                <div className={styles.preloader}>
                    <Spiner />
                </div>
            )}

            {imageUrl ? (
                <img
                    src={imageUrl}
                    alt={imageName}
                    loading="lazy"
                    onLoad={handleStopLoading}
                    onError={handleStopLoading}
                />
            ) : (
                // todo: нужна заглушка для отсутствующего изображения профиля
                <div className={styles.preloader}></div>
            )}
        </div>
    );
};

export default GalleryImage;
