import React, { useState } from 'react';
import styles from './profile-view.module.scss';
import { Container, Spiner, Tag, Typography } from '@/ui-kit';
import { useParams } from 'react-router-dom';
import { useGetProfileById } from '@/hooks/use-get-profile-by-id';
import { GalleryImages } from '@/components/gallery-images';
import { differenceInYearsResult } from '@/utils/difference-in-years-result';
import clsx from 'clsx';
import { ArrowTopFillIcon, LocationIcon } from '@/assets/icons';
import { OnlineStatus, OfflineStatus } from '@/assets/icons';
import { dataPurposes } from '@/constans/purposes';

interface Props {}

export const ProfileView: React.FC<Props> = () => {
    const { id } = useParams<{ id: string }>();
    const profileId = Number.isNaN(Number(id)) ? '' : Number(id);
    const { data: profile, isError, isLoading } = useGetProfileById(profileId);
    const [isVisibleDetails, setIsVisibleDetails] = useState(false);

    const currentPurpose = dataPurposes.find((purpose) => purpose.value === profile?.data?.purpose);

    const getIconComponent = (icon: React.FC<React.SVGProps<SVGSVGElement>>): React.ReactNode => {
        const IconComponent = icon;

        return <IconComponent />;
    };

    const toggleVisibleDetails = () => {
        setIsVisibleDetails((prev) => {
            const nextState = !prev;

            if (!nextState) {
                const topBlock = document.querySelector(`.${styles.card}`);
                if (topBlock) {
                    topBlock.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    });
                }
            }

            return nextState;
        });
    };

    return isLoading ? (
        <Spiner />
    ) : (
        <>
            <div className={clsx(styles.container, isVisibleDetails && styles.visibleDetails)}>
                <div className={styles.card}>
                    {<GalleryImages photos={profile?.data.photos ?? []} />}
                    <div className={clsx(styles.cardInfo, isVisibleDetails && styles.visibleDetails)}>
                        <Typography
                            typeColor="white"
                            fontWeight="medium"
                            variant="h3"
                            className={styles.cardInfoText}
                        >
                            {profile?.data.public_name}
                            {differenceInYearsResult(profile?.data?.dob) > 0
                                ? ', ' + differenceInYearsResult(profile?.data?.dob)
                                : null}
                        </Typography>
                        <Typography
                            typeColor="white"
                            fontWeight="normal"
                            variant="p"
                            className={styles.descriptionIntro}
                        >
                            {profile?.data.about_me}
                        </Typography>
                    </div>

                    <div
                        className={clsx(styles.arrowTop, isVisibleDetails && styles.visibleDetails)}
                        onClick={(event) => toggleVisibleDetails()}
                    >
                        <ArrowTopFillIcon />
                    </div>
                </div>

                <Container className={clsx(styles.userInfo, isVisibleDetails && styles.visibleDetails)}>
                    <div className={styles.userInfo__header}>
                        <Typography
                            typeColor="black"
                            fontWeight="medium"
                            variant="span"
                            className={clsx(styles.city)}
                        >
                            {profile?.data.is_online ? (
                                <>
                                    <OnlineStatus /> Онлайн
                                </>
                            ) : (
                                <>
                                    <OfflineStatus /> Оффлайн
                                </>
                            )}
                        </Typography>

                        <Typography
                            typeColor="black"
                            fontWeight="medium"
                            variant="span"
                            className={clsx(styles.city)}
                        >
                            <LocationIcon /> {profile?.data?.region?.name}
                        </Typography>
                    </div>

                    <Typography
                        fontWeight="bold"
                        variant="h3"
                        className={styles.userInfo__title}
                    >
                        {profile?.data.public_name}
                        {differenceInYearsResult(profile?.data?.dob) > 0
                            ? ', ' + differenceInYearsResult(profile?.data?.dob)
                            : null}
                    </Typography>

                    {profile?.data?.about_me && (
                        <>
                            <div className={styles.label}>
                                <Typography
                                    variant="span"
                                    fontWeight="bold"
                                >
                                    Обо мне
                                </Typography>
                            </div>

                            <Typography
                                className={styles.text}
                                variant="p"
                            >
                                {profile?.data?.about_me}
                            </Typography>
                        </>
                    )}

                    {currentPurpose && (
                        <Tag
                            icon={getIconComponent(currentPurpose.icon)}
                            className={clsx(styles.tagInfo, styles.isIcon)}
                        >
                            <div>
                                <Typography
                                    className={styles.tagInfo__title}
                                    variant="p"
                                >
                                    Я ищу
                                </Typography>
                                <Typography
                                    className={styles.tagInfo__text}
                                    variant="p"
                                    fontWeight="bold"
                                >
                                    {currentPurpose.text}
                                </Typography>
                            </div>
                        </Tag>
                    )}

                    {profile?.data?.travel_plans && (
                        <>
                            <div className={styles.label}>
                                <Typography
                                    variant="span"
                                    fontWeight="bold"
                                >
                                    Мои тревел мечты
                                </Typography>
                            </div>

                            <Typography
                                className={styles.text}
                                variant="p"
                            >
                                {profile?.data?.travel_plans}
                            </Typography>
                        </>
                    )}
                </Container>
            </div>
        </>
    );
};
