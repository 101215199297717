import { Button } from '@/ui-kit';
import styles from './button-submit-step.module.scss';
import clsx from 'clsx';
interface Props {
    isLoading?: boolean;
    stepNumber: number;
    href?: string;
    isDisabled?: boolean;
    isGray?: boolean;
    title?: string;
}

export const ButtonSubmitStep: React.FC<Props> = ({ isLoading, href, isDisabled, isGray, title = 'Продолжить' }) => {
    return (
        <div className={clsx(styles.wrapperButtonStep, isGray && styles.gray)}>
            <Button
                type="submit"
                variant="stretched"
                typeColor="orange"
                isLoading={isLoading}
                isDisabled={isDisabled}
                isLink={!!href}
                to={href}
            >
                {title}
            </Button>
        </div>
    );
};
