import React from 'react';
import SelectComponent from 'react-select';
import './select.scss';
import { DropdownIndicator } from '@/assets/icons';
import { Controller, useFormContext } from 'react-hook-form';
import clsx from 'clsx';

interface SelectOption {
    value: string | number;
    label: string;
}

interface Props {
    className?: string;
    wrapperClassName?: string;
    options: SelectOption[];
    onSearch?: (searchString: string) => void;
    isMulti?: boolean;
    placeholder?: string;
    name: string;
    isLoading?: boolean;
    onSelected?: (data: SelectOption) => void;
}

export const Select: React.FC<Props> = ({
    className,
    wrapperClassName,
    name,
    onSearch,
    options = [],
    isMulti = false,
    placeholder = 'Выберете город',
    isLoading,
    onSelected,
}) => {
    const { control } = useFormContext();
    return (
        <div className={clsx('react-select', wrapperClassName)}>
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value, ref } }) => {
                    const handleChange = (val: SelectOption) => {
                        onSelected?.(val);
                        onChange(val);
                    };
                    return (
                        <SelectComponent
                            className={className}
                            menuPlacement="auto"
                            options={options}
                            onChange={handleChange}
                            onInputChange={onSearch}
                            isMulti={isMulti}
                            placeholder={placeholder}
                            classNamePrefix="react-select"
                            openMenuOnClick={false}
                            isLoading={isLoading}
                            isSearchable
                            value={value}
                            components={{
                                DropdownIndicator: () => <DropdownIndicator />,
                                IndicatorSeparator: () => null,
                                NoOptionsMessage: (options) => <div className={'select-empty'}>Ничего не найдено</div>,
                            }}
                            ref={ref}
                        />
                    );
                }}
            />
        </div>
    );
};
