import Router from './router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import WebApp from '@twa-dev/sdk';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { delay } from '@/utils/helpers';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
        },
    },
});

const isIOS = () => {
    if (typeof window === `undefined` || typeof navigator === `undefined`) return false;

    return /iPhone|iPad|iPod/i.test(
        navigator.userAgent ||
            navigator.vendor ||
            ((window as any).opera && (window as any).toString() === `[object Opera]`),
    );
};

/**
 * хак для iOS при фокусе в инпут, выравнивает вебвью
 */
const hackForFocusInputOnIOS = () => {
    WebApp.onEvent('viewportChanged', async () => {
        await delay(200);
        if (WebApp.platform === 'ios') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (document.activeElement?.tagName === 'INPUT' || document.activeElement?.tagName === 'TEXTAREA') {
                await delay(300);
                document.activeElement.scrollIntoView();
                await delay(10);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }
    });
};

const addClassForPlatform = () => {
    document.documentElement.classList.add(WebApp.platform);
    if (isIOS()) {
        document.documentElement.classList.add('web-ios');
    }
};

export const App = () => {
    useEffect(() => {
        WebApp.ready();
        WebApp.disableVerticalSwipes();
        WebApp.BackButton.isVisible = true;
        WebApp.BackButton.show();
        WebApp.expand();
        addClassForPlatform();
        hackForFocusInputOnIOS();
    }, []);
    return (
        <QueryClientProvider client={queryClient}>
            <Router />
            <ToastContainer className="toastContainer" />
        </QueryClientProvider>
    );
};
