import { generatePath, NavLink } from 'react-router-dom';
import clsx from 'clsx';
import styles from './menu-app.module.scss';
import { menuData } from './menu-data';
import { useGetLikesCounter } from '@/hooks/use-get-like-counter';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { routesApp } from '@/constans/routes';

interface Props {
    className?: string;
    itemsMapCount?: {
        [key: string]: number;
    };
}

interface IItemsCount {
    [key: string]: number;
}

export const MenuApp: React.FC<Props> = ({ className, itemsMapCount }) => {
    const { data } = useGetLikesCounter();
    const [itemsCount, setItemsCount] = useState<IItemsCount>({});
    const navigate = useNavigate();

    useEffect(() => {
        if (data) {
            setItemsCount((prev) => ({
                ...prev,
                liked: data.data.count,
            }));
        }
    }, [data]);

    const handleMenuClick = (link: string) => {
        navigate(generatePath(link));
    };

    return (
        <nav className={clsx(className, styles.menu)}>
            <ul className={styles.list}>
                {menuData.map((item) => {
                    const IconComponent = item.icon;
                    return (
                        <li
                            key={item.title}
                            className={styles.item}
                            onClick={() => handleMenuClick(item.path)}
                        >
                            <NavLink
                                to={item.path}
                                className={({ isActive }) =>
                                    isActive ? styles.link + ' ' + styles.active : styles.link
                                }
                            >
                                <IconComponent />
                                {itemsCount && itemsCount[item.value] > 0 && (
                                    <div className={styles.counter}>
                                        {itemsCount[item.value] >= 100 ? 99 : itemsCount[item.value]}
                                    </div>
                                )}
                            </NavLink>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};
