import clsx from 'clsx';
import styles from './navigation.module.scss';
import React from 'react';

interface Props {
    onChange: (idx: number, dir: 'left' | 'right') => void;
    currentIndex: number;
}

export const Navigation: React.FC<Props> = ({ onChange, currentIndex }) => {
    const handleChangeLeftNavigate = (dir: 'left' | 'right') => {
        onChange(currentIndex, dir);
    };
    return (
        <div className={clsx(styles.navigation, 'pressable')}>
            <div
                onClick={() => handleChangeLeftNavigate('left')}
                className={clsx(styles.navigationItem, styles.navigationLeft)}
            >
                Left
            </div>
            <div
                onClick={() => handleChangeLeftNavigate('right')}
                className={clsx(styles.navigationItem, styles.navigationRight)}
            >
                Right
            </div>
        </div>
    );
};
