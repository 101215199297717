import { HeaderMenu } from '@/components/header-menu';
import styles from './messages.module.scss';

import { useGetMatches } from '@/hooks/use-get-matches';
import { useGetChats } from '@/hooks/use-get-chats';
import { useGetSettings } from '@/hooks/use-get-settings';
import { NewCouples } from '@/components/new-couples';
import { MessagesList } from '@/components/messages-list';
import { useMemo } from 'react';

export function Messages() {
    const { data, isLoading: isLoadingMatches } = useGetMatches();
    const { data: chatList, isLoading: isLoadingChats } = useGetChats();
    useGetSettings();

    const isLoading = isLoadingMatches || isLoadingChats;
    const isEmptyMessage = chatList?.data?.length === 0 && data?.data?.length === 0;

    const newCouples = useMemo(() => {
        const fetchedCouples = data?.data;
        const fetchedChats = chatList?.data;

        if (fetchedCouples && fetchedChats) {
            return fetchedCouples.filter(
                (couple: { id: number }) => !fetchedChats.some((chat) => chat.profile.id === couple.id && chat.message),
            );
        }

        return data?.data;
    }, [chatList?.data, data?.data]);

    const getSortedChatList = () => {
        const messages = chatList?.data || [];

        return messages.sort((a, b) => Date.parse(b.message?.created_at) - Date.parse(a.message?.created_at));
    };

    return (
        <div className={styles.messages}>
            <HeaderMenu title="Сообщения" />
            <NewCouples
                matches={newCouples}
                isLoading={isLoading}
            />
            <MessagesList
                isLoading={isLoading}
                chatList={getSortedChatList()}
                isEmptyMessage={isEmptyMessage}
            />
        </div>
    );
}
